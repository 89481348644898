.SuggestedResponse {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    background: transparent;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    &[disabled] {
      background: transparent;
      border-color: transparent;
      opacity: 0.5;
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    min-width: 20rem;
  }

  &__icon {
    margin: 0 0.5rem;
    min-height: 1.75rem;
  }

  &__loader {
    margin: 0 -1.75rem 0 0;
    display: block;
    width: 1.75rem;
    .Spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 0 !important;
      }
    }
  }
}
